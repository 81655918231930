import { PAGE_PRODUCTS } from 'constants';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SubjectLink = ({ id, label }) => {
  return (
    <Link to={PAGE_PRODUCTS + '?filter=subject%3D' + id} target={'_blank'}>
      {label}
    </Link>
  );
};

SubjectLink.propTypes = {
  id: PropTypes.integer,
  label: PropTypes.string,
};

export default SubjectLink;
