import { CHART_TYPE } from 'constants';
import { useFetchFilteredChartsData } from 'hooks/useFetchFilteredChartsData';
import useLocalStorage from 'hooks/useLocalStorage';
import PropTypes from 'prop-types';
import { useState } from 'react';
import TableCardMonopolizationInput from './TableCardMonopolizationInput';

const TableCardMonopolization = ({ template: ColumnCard, width }) => {
  const [monopolization, setMonopolication] = useLocalStorage('monopolication');

  const [percentage, setPercentage] = useState(monopolization || 40);
  const {
    data: dataRaw,
    isLoading,
    isRefetching,
    refetch,
  } = useFetchFilteredChartsData({
    type: CHART_TYPE.MONOPOLIZATION,
    restricts: { ratio: [percentage] },
  });

  const data = prepareDataForCard(dataRaw);

  const onChange = (v) => {
    setPercentage(v);
    setMonopolication(v);
    refetch();
  };

  const card = {
    title: (
      <span className="flex items-center">
        <span>Монополизация</span>{' '}
        <TableCardMonopolizationInput onChange={onChange} value={percentage} />
      </span>
    ),
    labels: ['Шт'],
    isQuestion: true,
    questionText:
      'Параметр, показывающий какое кол-во продавцов создают заданный процент от оборота рынка',
    tabs: [
      {
        info: [
          {
            label: 'Продавцы',
            key: 'sellers',
            key_percent: 'sellers_percent',
            type: 'number',
          },
          {
            label: 'Бренды',
            key: 'brands',
            key_percent: 'brands_percent',
            type: 'number',
          },
          {
            label: 'Товары',
            key: 'products',
            key_percent: 'products_percent',
            type: 'number',
          },
        ],
      },
    ],
  };

  return (
    <ColumnCard
      key={`column-monopoly`}
      isLoading={isLoading || isRefetching}
      card={card}
      data={data}
      index={`column-monopoly`}
      isQuestion={card.isQuestion}
      isQuestionToRight={card.isQuestionToRight}
      questionText={card.questionText}
      width={width}
      activeDefault={card.activeDefault !== undefined ? card.activeDefault : 0}
    />
  );
};
TableCardMonopolization.propTypes = {
  template: PropTypes.node,
  width: PropTypes.number,
};

export default TableCardMonopolization;

const getDataFromMonopolicationItem = (item, key) => {
  const { monopolization_qty, monopolization_percent } = item || {};
  return { [key]: monopolization_qty, [key + '_percent']: monopolization_percent };
};

const prepareDataForCard = (data) => {
  const { products, brands, sellers } = data || {};

  return {
    ...getDataFromMonopolicationItem(products, 'products'),
    ...getDataFromMonopolicationItem(brands, 'brands'),
    ...getDataFromMonopolicationItem(sellers, 'sellers'),
  };
};
