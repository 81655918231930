import cn from 'classnames';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Burger } from 'assets/images/burger.svg';
import chromePNG from 'assets/images/chromePNG.png';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { ReactComponent as Search } from 'assets/images/search.svg';
import Chats from 'components/Chats';
import Navigation from 'components/Navigation';
import SearchInput from 'components/SearchInput';
import TariffInfo from 'components/TariffInfo';
import { PAGE_PROFILE_SUBSCRIPTIONS } from 'constants/pages';
import { useAuth } from 'context/auth.context';
import { useUI } from 'context/ui.context';
// import { useMainLinkDefault } from 'hooks/useLocationPath';
// import AnalyticType from '../AnalyticType/AnalyticType';
import { useAnalyticType } from 'context/analytic.type.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { DesktopChats } from '../Chats/Chats';
import { InnerNotification } from '../Notification';

const Header = () => {
  const { logout, setUser, user, tariff } = useAuth();
  const { setActiveModal, activeModal, isDesktop } = useUI();
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [isEmailHover, setIsEmailHover] = useState(false);

  const { isInner, setOuterTypeChange } = useAnalyticType();
  const { changeFilterType } = useFilterReducer();
  // const mainLink = useMainLinkDefault();
  const history = useHistory();

  const handleLogout = async () => {
    logout().then(() => setUser(null));
  };

  const handleGoToMain = () => {
    setOuterTypeChange();

    if (isInner) {
      changeFilterType({ nextLink: '/navigation', prevLink: window.location.pathname });
    }

    history.push('/navigation');
  };
  const isSeo = window.location.pathname.startsWith('/seo');
  const items = [
    { link: PAGE_PROFILE_SUBSCRIPTIONS, name: 'Подписки' },
    { link: '/profile', name: 'Профиль' },
    { link: '/profile/referrals', name: 'Реферальная программа' },
    { link: '/profile/tariffs', name: 'Тарифы' },
  ];
  return (
    <>
      <header
        className={cn(
          'items-center lg:items-stretch flex justify-between shadow-sm h-16 px-6 relative header mb-0 z-[50]',
          {
            ['z-[10102]']: activeModal === 'search',
          },
        )}
      >
        <div className="mr-auto lg:mr-0 flex items-stretch flex-grow">
          <button className="flex items-center" onClick={handleGoToMain}>
            <Logo className="self-center" />
          </button>
          {isDesktop && (
            <>
              {/*<AnalyticType />*/}

              {!isSearchExpanded && !window.location.pathname.startsWith('/navigation') && (
                <Navigation />
              )}
              {!isSearchExpanded && <div className="flex grow" />}
              {
                tariff && !isSeo && (
                  <SearchInput isExpanded={isSearchExpanded} setIsExpanded={setIsSearchExpanded} />
                ) /*: (
                <div className="flex grow" />
              )*/
              }
              {!isSearchExpanded && (
                <div className="flex items-center">
                  <div className="h-8 mx-4 bg-[#E3E3E3] w-px"></div>
                  <a
                    href="https://chromewebstore.google.com/detail/wecheck/nbhkkkflcfheikkjmcphbfhbleblccgl"
                    target="_blank"
                    rel="noreferrer"
                    className="self-center justify-self-end"
                  >
                    <div className="flex items-center px-3 bg-[rgb(245,_239,_203,_0.80)] rounded-[8px] h-8 py-2">
                      <img src={chromePNG} alt="chrome" className="w-[22px] h-[22px]" />
                      <span className="block ml-1">Плагин для Chrome</span>
                    </div>
                  </a>
                </div>
              )}
              <DesktopChats />
              <div className="flex items-center">
                <div
                  className="flex items-center relative h-full cursor-pointer"
                  onMouseEnter={() => setIsEmailHover(true)}
                  onMouseLeave={() => setIsEmailHover(false)}
                >
                  <div className={cn('text-green flex', { 'text-black': isEmailHover })}>
                    <span className="self-center block">{user && user.email}</span>
                  </div>
                  {isEmailHover && (
                    <div
                      className="absolute bg-white rounded-[8px] flex flex-col top-[62px] right-0 py-[6px]"
                      style={{ boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.14)' }}
                    >
                      <div className="px-4 pt-2 w-max">
                        <TariffInfo />
                      </div>
                      {items.map(({ link, name }) => {
                        return (
                          <a
                            href={link}
                            className="px-4 py-2 hover:text-green text-black w-full text-left"
                            key={link}
                          >
                            <span className="block text-[14px] leading-[16px] font-medium">
                              {name}
                            </span>
                          </a>
                        );
                      })}
                      {user && (
                        <>
                          <div className="h-px mx-4 bg-[#E3E3E3]" />
                          <button
                            className="px-4 py-2 hover:text-green text-black w-full text-left"
                            onClick={handleLogout}
                          >
                            <span className="block text-[14px] leading-[16px] font-medium">
                              {'Выйти'}
                            </span>
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        {!isDesktop && (
          <>
            <Chats />
            <div
              onClick={() =>
                activeModal === 'search' ? setActiveModal('') : setActiveModal('search')
              }
              className="flex w-6 justify-center items-center"
            >
              <Search />
            </div>
            <div
              onClick={() => setActiveModal('menu')}
              className="flex h-5 w-8 justify-center border-l-2 items-center ml-3 pl-3"
            >
              <Burger />
            </div>
          </>
        )}
      </header>
      <InnerNotification />
    </>
  );
};

export default Header;
