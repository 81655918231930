import cn from 'classnames';
import { Fragment, useCallback } from 'react';
import { useHistory } from 'react-router';

import Arrow from 'assets/icons/Arrow';
import CategoryLink from 'components/Links/CategoryLink';
import SubjectLink from 'components/Links/SubjectLink';
import { ProductFavourite } from 'components/ProductHeader/components/ProductFavourite';
import { PAGE_PRODUCTS } from 'constants/pages';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useProduct } from 'context/product.context';
import { useUI } from 'context/ui.context';
import { useUserTariffs } from 'context/user.tariffs.context';
import PropTypes from 'prop-types';

export const ProductDataRow = ({ isInner }) => {
  const { isDesktop } = useUI();

  const { addSeller, addBrands } = useFilterReducer();
  const { productData } = useProduct();

  const { isSellersAvailable } = useUserTariffs();

  const history = useHistory();

  const handleAddBrandToFilter = useCallback(
    (value) => {
      if (!value) return;
      addBrands({
        key: isInner ? productData?.brand_id : productData?.brand?.id,
        title: isInner ? productData?.brand : productData?.brand?.name,
        nextLink: PAGE_PRODUCTS,
      });

      history.push(PAGE_PRODUCTS);
    },
    [productData, addBrands, history, isInner],
  );

  const handleAddSellerToFilter = useCallback(() => {
    if (!isSellersAvailable) return;

    addSeller({
      key: isInner ? productData?.seller_id : productData?.seller?.id,
      title: isInner ? productData?.seller : productData?.seller?.name,
      nextLink: PAGE_PRODUCTS,
    });

    history.push(PAGE_PRODUCTS);
  }, [productData, addSeller, isSellersAvailable, history, isInner]);

  const getFormattedBarcode = (xs) =>
    xs.length ? [xs.slice(0, 5), ...getFormattedBarcode(xs.slice(5), 5)] : [];
  const items = [
    {
      label: productData?.isInner ? 'Артикул WB' : 'Артикул',
      data: isInner ? productData?.inner_product : productData?.articule,
    },
    {
      label: 'Артикул продавца',
      data: productData?.supplier_article,
    },
    {
      label: 'Баркод',
      data: productData?.barcode && getFormattedBarcode(productData?.barcode + '').join(''),
    },
    {
      label: 'Бренд',
      data: productData?.brand?.name || productData?.brand,
      onClick: handleAddBrandToFilter,
    },
    {
      label: 'Продавец',
      data: productData?.seller?.name || productData?.seller,
      onClick: handleAddSellerToFilter,
    },
    {
      label: 'Склад',
      data: isInner ? productData?.warehouse : productData?.delivery_from,
    },
  ];
  const commissionKey = productData?.isInner ? 'commission_percent' : 'comission_percent';
  return (
    <>
      <div className="flex flex-col divide-y lg:flex-row lg:divide-y-0 lg:divide-x divider-gray-230 lg:py-2 lg:space-x-4">
        {items.map((item, i) => {
          let resultData = item.data ? item.data : item.label === 'Бренд' ? '-' : item.data;
          if (typeof resultData === 'object') {
            resultData = resultData?.name;
          }
          return (
            <Fragment key={`product-data-${i}`}>
              {resultData && (
                <div
                  className={cn('flex flex-nowrap items-center py-3 lg:py-0', {
                    ['lg:pl-4']: i !== 0,
                  })}
                >
                  <span className="pr-3 text-gray-400">{item.label}</span>
                  {item.onClick ? (
                    <button
                      onClick={() => item.onClick(item.data)}
                      className="text-green flex items-center lg:text-black cursor-pointer hover:text-green"
                    >
                      {resultData}
                      {!isDesktop && <Arrow className="text-gray-300 ml-3" />}
                    </button>
                  ) : (
                    <span>{resultData}</span>
                  )}
                </div>
              )}
            </Fragment>
          );
        })}
        <br className="hidden lg:block" />
        {!productData?.isInner && <ProductFavourite />}
      </div>
      <div className="flex">
        {productData?.category && (
          <div className="block lg:flex item-center py-3 lg:py-2 pr-2">
            <span className="block text-green text-base font-medium mr-2">Категория:</span>
            <span className="pt-0.5">
              <CategoryLink id={productData?.category.id} label={productData?.category.name} />
            </span>
          </div>
        )}
        {productData?.subject && (
          <div className={cn('block lg:flex item-center py-3 lg:py-2 lg:h-fit')}>
            <span
              className={cn('block text-green text-base font-medium mr-2', {
                'pl-2 border-l border-gray-230': productData?.category,
              })}
            >
              Предмет:
            </span>
            <span className="pt-0.5">
              <SubjectLink id={productData?.subject.id} label={productData?.subject.name} />
            </span>
          </div>
        )}
        <div className={cn('block lg:flex item-center py-3 lg:py-2 lg:h-fit')}>
          <span
            className={cn(
              'block text-green text-base font-medium mr-2 pl-2 border-l border-gray-230 ml-2',
            )}
          >
            Комиссия:
          </span>
          <span className="pt-0.5">
            {!productData?.[commissionKey] || productData?.[commissionKey] === '-'
              ? '-'
              : `${productData?.[commissionKey]}%`}
          </span>
        </div>
      </div>
    </>
  );
};

ProductDataRow.propTypes = {
  isInner: PropTypes.bool,
};
