import { useEffect } from 'react';

import { PAGE_GROWTH, PAGE_SEO_BY_PHRASE, PAGE_SEO_MONITORING } from 'constants';
import { F, F_INNER } from 'constants/filter';
import {
  PAGE_INNER_PRODUCTS,
  PAGE_INNER_SALES,
  PAGE_MAIN,
  PAGE_PRODUCT,
  PAGE_PRODUCTS,
} from 'constants/pages';
import { useAnalyticType } from 'context/analytic.type.context';
import { useAuth } from 'context/auth.context';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import {
  PLATFORM_OZON,
  PLATFORM_WB,
  fixProductDateFiltersParams,
  getPeriodByPath,
} from 'context/filter/filter.context.reducer.methods';
import {
  isGrowthPage,
  isInnerTablePage,
  isProductPage,
  isSeoResultPage,
  isSubjectPage,
  isTablePage,
} from 'utils/pages';

const FILTER_SEARCH_PARAM_KEY = 'filter';
const NAME_SEARCH_PARAM_KEY = 'name';
const RESTRICTS_SEARCH_PARAM_KEY = 'restricts';

const MAIN_FILTER_PARAMS = [
  F.CATEGORY,
  F.BRAND,
  F.SELLER,
  F.SUBJECT,
  F.PLATFORM,
  F.WAREHOUSE,
  F.DATE,
  F.IS_NEW,
  F.IS_PROMOTIONAL,
  F.HAS_ADVERTISING,
  F.SORT,
  F.ORDER,
];

const PRODUCT_FILTER_PARAMS = [F.DATE, F.SORT, F.ORDER, F.IS_PROMOTIONAL];
const SEO_FILTER_PARAMS = [F.SORT, F.ORDER];
const GROWTH_FILTER_PARAMS = [
  F.CATEGORY,
  F.BRAND,
  F.SELLER,
  F.PLATFORM,
  F.WAREHOUSE,
  F.SORT,
  F.ORDER,
  F.PERIOD,
];

const INNER_FILTER_PARAMS = [
  F.DATE,
  F.SORT,
  F.ORDER,
  F.WAREHOUSE,
  F_INNER.API_KEYS,
  F_INNER.CATEGORY,
  F_INNER.SUBJECT,
  F_INNER.BRAND,
  F_INNER.QUANTITY,
];

const getFilterSearchParams = (filterInitial) => {
  const href = new URL(window.location.href);
  const filterSearchParams = href.searchParams.get(FILTER_SEARCH_PARAM_KEY);
  if (!filterSearchParams) return null;

  const params = new URLSearchParams(filterSearchParams);

  const filter = {};

  for (let key of params.keys()) {
    const param = params.get(key);

    if (!param) continue;

    const type = typeof filterInitial[key];

    if (!type) continue;

    let value = null;

    if (type === 'number') {
      value = !param ? 0 : +param;
    } else if (Array.isArray(filterInitial[key])) {
      value = param.split(',').map((value) => +value || value);
    } else {
      value = param;
    }

    filter[key] = value;
  }

  // платформа обязательный параметр для фильтра
  let platform = filter[F.PLATFORM];
  if (!platform || (platform !== PLATFORM_OZON && platform !== PLATFORM_WB)) {
    filter[F.PLATFORM] = PLATFORM_WB;
  }

  return filter;
};

const getNameSearchParams = () => {
  const href = new URL(window.location.href);
  const nameSearchParams = href.searchParams.get(NAME_SEARCH_PARAM_KEY);

  if (!nameSearchParams) return null;

  const params = new URLSearchParams(nameSearchParams);

  const name = {};

  for (let key of params.keys()) {
    const param = params.get(key);

    if (!param) continue;

    name[key] = param;
  }

  return name;
};

const getRestrictsSearchParams = () => {
  const href = new URL(window.location.href);
  const restrictsSearchParams = href.searchParams.get(RESTRICTS_SEARCH_PARAM_KEY);

  if (!restrictsSearchParams) return null;

  const params = new URLSearchParams(restrictsSearchParams);

  const restricts = {};

  for (let key of params.keys()) {
    const param = params.get(key);

    if (!param) continue;

    restricts[key] = param.split(',');
  }

  return restricts;
};

const cleanFilterPathParams = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete(FILTER_SEARCH_PARAM_KEY);
  url.searchParams.delete(NAME_SEARCH_PARAM_KEY);
  url.searchParams.delete(RESTRICTS_SEARCH_PARAM_KEY);
  window.history.replaceState(null, null, url.href);
};

const setFilterToPath = ({ filterParams, nameParams, restrictsParams }) => {
  if (!filterParams && !nameParams && !restrictsParams) return;
  const url = new URL(window.location.href);

  if (filterParams) {
    const filterSearchParams = new URLSearchParams(filterParams).toString();
    url.searchParams.set(FILTER_SEARCH_PARAM_KEY, filterSearchParams);
  }

  if (nameParams) {
    const nameSearchParams = new URLSearchParams(nameParams).toString();
    url.searchParams.set(NAME_SEARCH_PARAM_KEY, nameSearchParams);
  }

  if (restrictsParams) {
    const restrictsSearchParams = new URLSearchParams(restrictsParams).toString();
    url.searchParams.set(RESTRICTS_SEARCH_PARAM_KEY, restrictsSearchParams);
  }

  window.history.replaceState(null, null, url.href);
};

const getMainFilterParams = (params) => {
  return Object.keys(params)
    .filter((key) => MAIN_FILTER_PARAMS.includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: params[key] });
    }, {});
};

const getProductFilterParams = (params) => {
  return Object.keys(params)
    .filter((key) =>
      [...PRODUCT_FILTER_PARAMS, params.platform === '1' ? F.WAREHOUSE : F.WAREHOUSE_TYPE].includes(
        key,
      ),
    )
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: params[key] || '' });
    }, {});
};

const getSeoFilterParams = (params) => {
  return Object.keys(params)
    .filter((key) => SEO_FILTER_PARAMS.includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: params[key] });
    }, {});
};

const getSeoByPhraseFilterParams = (params) => {
  return Object.keys(params)
    .filter((key) => [F.DATE, F.WAREHOUSE_TYPE].includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: params[key] });
    }, {});
};

const getSeoMonitoringFilterParams = (params) => {
  return Object.keys(params)
    .filter((key) => [F.DATE].includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: params[key] });
    }, {});
};

const getGrowthFilterParams = (params) => {
  return Object.keys(params)
    .filter((key) => GROWTH_FILTER_PARAMS.includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: params[key] });
    }, {});
};

const getInnerFilterParams = (params) => {
  return Object.keys(params)
    .filter((key) => INNER_FILTER_PARAMS.includes(key))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: params[key] });
    }, {});
};

const getPageFilterParams = (params) => {
  const isMain = isTablePage();
  const isProduct = isProductPage();
  const isSubject = isSubjectPage();
  const isGrowth = isGrowthPage();
  const isSeoResult = isSeoResultPage();
  const isSeoMonitoring = window.location.pathname.includes(PAGE_SEO_MONITORING);
  const isSeoByPhrase = window.location.pathname.includes(PAGE_SEO_BY_PHRASE);
  const isInner = isInnerTablePage();
  if (
    !isMain &&
    !isProduct &&
    !isSubject &&
    !isSeoResult &&
    !isGrowth &&
    !isInner &&
    !isSeoMonitoring &&
    !isSeoByPhrase
  ) {
    return null;
  }
  if (isSeoByPhrase) {
    return getSeoByPhraseFilterParams(params);
  }
  if (isSeoMonitoring) {
    return getSeoMonitoringFilterParams(params);
  }
  if (isProduct) {
    return getProductFilterParams(params);
  }

  if (isSeoResult) {
    return getSeoFilterParams(params);
  }

  if (isGrowth) {
    return getGrowthFilterParams(params);
  }

  if (isInner) {
    return getInnerFilterParams(params);
  }

  return getMainFilterParams(params);
};

const getPageNameParams = (params) => {
  const isMain = isTablePage();
  const isProduct = isProductPage();

  if (isMain || isProduct) {
    return params;
  }

  return null;
};

const getPageRestrictsParams = (params) => {
  const isMain = isTablePage();
  const isProduct = isProductPage();
  const isInner = isInnerTablePage();

  if (isMain || isProduct || isInner) {
    return params;
  }

  return null;
};

export const useMainLink = () => {
  const { filter } = useFilterReducer();

  const url = new URL(window.location.origin + PAGE_MAIN);

  const params = new URLSearchParams(filter).toString();
  url.searchParams.set(FILTER_SEARCH_PARAM_KEY, params);

  return `${PAGE_MAIN}${url.search}`;
};

export const useMainLinkDefault = () => {
  const { filterInitialMainOuter } = useFilterReducer();

  const url = new URL(window.location.origin + PAGE_MAIN);

  const params = new URLSearchParams(filterInitialMainOuter).toString();
  url.searchParams.set(FILTER_SEARCH_PARAM_KEY, params);

  return `${PAGE_MAIN}${url.search}`;
};

export const useGrowthLink = () => {
  const { filter } = useFilterReducer();

  const growthParams = getGrowthFilterParams(filter);

  if (!growthParams[F.PERIOD]) {
    growthParams[F.PERIOD] = getPeriodByPath({ path: PAGE_GROWTH, data: filter[F.DATE] });
  }

  const url = new URL(window.location.origin + PAGE_GROWTH);

  const params = new URLSearchParams(growthParams).toString();
  url.searchParams.set(FILTER_SEARCH_PARAM_KEY, params);

  return `${PAGE_GROWTH}${url.search}`;
};

const getInnerTableUrl = () => {
  const from = new URLSearchParams(window.location.search).get('from');
  switch (from) {
    case 'sales':
      return PAGE_INNER_SALES;
    default:
      return PAGE_INNER_PRODUCTS;
  }
};

export const useProductsLink = (isInner) => {
  const { filter } = useFilterReducer();
  const url = new URL(window.location.origin + PAGE_MAIN);
  const nextLink = isInner ? getInnerTableUrl() : PAGE_PRODUCTS;
  const params = new URLSearchParams(filter).toString();
  url.searchParams.set(FILTER_SEARCH_PARAM_KEY, params);

  return `${nextLink}${url.search}`;
};

export const useSellerLink = (sellerId) => {
  const { filter } = useFilterReducer();

  const url = new URL(window.location.origin + PAGE_MAIN);

  const params = new URLSearchParams({
    ...filter,
    seller: sellerId ? sellerId : filter.seller,
  }).toString();
  url.searchParams.set(FILTER_SEARCH_PARAM_KEY, params);

  return `${PAGE_PRODUCTS}${url.search}`;
};

export const useBrandLink = (brandId) => {
  const { filter } = useFilterReducer();

  const url = new URL(window.location.origin + PAGE_MAIN);

  const params = new URLSearchParams({
    ...filter,
    brand: brandId ? brandId : filter.brand,
  }).toString();
  url.searchParams.set(FILTER_SEARCH_PARAM_KEY, params);

  return `${PAGE_PRODUCTS}${url.search}`;
};

export const useProductLink = (id) => {
  const { productFilterDefault } = useFilterReducer();
  const productFilterParams = getProductFilterParams(
    fixProductDateFiltersParams(productFilterDefault),
  );
  const url = new URL(window.location.origin + PAGE_PRODUCT);

  const params = new URLSearchParams(productFilterParams).toString();
  url.searchParams.set(FILTER_SEARCH_PARAM_KEY, params);

  return `${PAGE_PRODUCT}/${id}${url.search}`;
};

export const useLocationPath = () => {
  const { isLoading: isUserDataLoading } = useAuth();
  const { isInner, isLoaded: isAnalyticTypeLoaded } = useAnalyticType();

  const { filter, filterText, filterRange, setFilterInitial, setFilter, isLoaded, filterInitial } =
    useFilterReducer();

  const isMain = isTablePage();
  const isProduct = isProductPage();
  const isSubject = isSubjectPage();
  const isSeoResult = isSeoResultPage();
  const isGrowth = isGrowthPage();

  const isSeoMonitoring = window.location.pathname.includes(PAGE_SEO_MONITORING);
  const isSeoByPhrase = window.location.pathname.includes(PAGE_SEO_BY_PHRASE);
  const isPathWithFilter =
    isMain ||
    isProduct ||
    isSubject ||
    isSeoResult ||
    isGrowth ||
    isInner ||
    isSeoMonitoring ||
    isSeoByPhrase;
  let filterParams = getFilterSearchParams(filterInitial);
  let nameParams = getNameSearchParams(NAME_SEARCH_PARAM_KEY);
  let restrictsParams = getRestrictsSearchParams(RESTRICTS_SEARCH_PARAM_KEY);

  useEffect(() => {
    if (isUserDataLoading || isLoaded || !isAnalyticTypeLoaded) {
      return;
    }

    if (filterParams || nameParams) {
      //при первой загрузке если есть фильтр в пути
      setFilter({ filterParams, nameParams, restrictsParams });
    } else {
      //при первой загрузке если нет фильтра в пути
      setFilterInitial();
    }
  }, [
    filterParams,
    nameParams,
    isUserDataLoading,
    setFilter,
    setFilterInitial,
    isLoaded,
    isPathWithFilter,
    filter,
    restrictsParams,
    isAnalyticTypeLoaded,
  ]);

  useEffect(() => {
    if (isUserDataLoading || !isLoaded) {
      return;
    }
    if (!isPathWithFilter) {
      // удалять фильтр из пути, если страница без фильтра
      // console.log('FILTER -> CLEAN');
      cleanFilterPathParams();
    } else {
      // при изменении фильтра менять путь
      const filterParams = getPageFilterParams(filter);
      const nameParams = getPageNameParams(filterText);
      const restrictsParams = getPageRestrictsParams(filterRange);
      setFilterToPath({ filterParams, nameParams, restrictsParams });
    }
  }, [isPathWithFilter, filter, isLoaded, isUserDataLoading, filterText, filterRange]);
};
