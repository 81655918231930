import cn from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useGrowthLink, useMainLink } from 'hooks/useLocationPath';
import { isGrowthPage, isMainPage } from 'utils/pages';

export const PAGE_TITLE_STYLES = 'font-bold text-lg leading-[0.8] lg:text-2xl lg:leading-[0.8]';
const NavigationItem = ({ isActive, children, to }) => {
  const history = useHistory();
  const { setFilterDefault } = useFilterReducer();

  const className = cn(PAGE_TITLE_STYLES, {
    ['text-gray-250 hover:text-green transition-all']: !isActive,
  });

  const handleFilterUpdate = () => {
    //при переходе между страницами обновлять некоторые параметры фильтра
    setFilterDefault({ nextLink: to, prevLink: window.location.pathname });

    history.push(to);
  };

  return (
    <li className="px-4">
      {isActive ? (
        <h1 className={className}>{children}</h1>
      ) : (
        <button className={className} onClick={handleFilterUpdate}>
          {children}
        </button>
      )}
    </li>
  );
};
NavigationItem.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export const Navigation = () => {
  const linkMain = useMainLink();
  const linkGrowth = useGrowthLink();
  const isProd = window.location.hostname === 'go.wecheck.ru';
  // Fix for prod hidding if !Admin
  return (
    <nav>
      <ul className="flex divide-x -mx-4">
        <NavigationItem to={linkMain} isActive={isMainPage()}>
          Аналитика ассортимента
        </NavigationItem>
        {!isProd && (
          <NavigationItem to={linkGrowth} isActive={isGrowthPage()}>
            Темпы роста
          </NavigationItem>
        )}
      </ul>
    </nav>
  );
};
