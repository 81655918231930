import React from 'react';
import ReactDOM from 'react-dom';

import App from 'components/App';
import AppProviders from 'context';

import './assets/styles/index.css';

import ErrorFallback from 'components/ErrorFallback';
import ErrorFallbackCommon from 'components/ErrorFallbackCommon';
import { IS_PRODUCTION } from 'constants/environment';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://5cead1a609704dfca1dc029def044986@o891338.ingest.sentry.io/5839843',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import('react-gtm-module').then((TagManager) => {
  if (IS_PRODUCTION) {
    const tagManagerArgs = {
      gtmId: 'GTM-T6DSKXK',
    };

    TagManager.initialize(tagManagerArgs);
  }
});

import('react-yandex-metrika/lib/init').then((YMInitializer) => {
  if (IS_PRODUCTION) {
    YMInitializer.default([54208747], { webvisor: true }, '2');
  }
});

import('ga-gtag').then(({ install }) => {
  if (IS_PRODUCTION) {
    install('G-GSREK1D7HK');
  }
});

const root = document.getElementById('root');
root.setAttribute('notranslate', true);

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <Sentry.ErrorBoundary
        fallback={process.env.NODE_ENV === 'production' ? ErrorFallbackCommon : ErrorFallback}
        showDialog
      >
        <App />
      </Sentry.ErrorBoundary>
    </AppProviders>
  </React.StrictMode>,
  root,
);
