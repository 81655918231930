const TAB_FIRST = 'requests';
const TAB_SECOND = 'words';
const BY_PRODUCTS = 'products';
const BY_PHRASES = 'phrases';
const BY_HINTS = 'hints';
const NESTED = 'nested';
const SEO_COMPARE = 'seo_compare';
const SEO_MONITORING_PRODUCTS = 'seo_monitoring_products';
const SEO_MONITORING_PHRASES = 'seo_monitoring_phrases';
const SEO_BY_PHRASE = 'seo_by_phrase';
const PAGE_SEO_COMPARE = '/seo/compare';
const PAGE_SEO_MONITORING = '/seo/monitoring';
const PAGE_SEO_BY_PHRASE = '/seo/by-phrase';
const PAGE_SEO_HINTS = '/seo/hints';
const PAGE_SEO_SMART = '/seo/smart';
const PAGE_SEO_SMART_DESCRIPTION = '/seo/smart-description';
const PAGE_SEO_CLUSTERS = '/seo/clusters';
const SEO_CLUSTERS_BY_PHRASE = 'by-phrase';
const SEO_CLUSTERS_BY_ARTICLE = 'by-article';
const SEO_CLUSTERS_BY_GROUP = 'by-group';
const PAGE_SEO_CLUSTERS_REQUEST = PAGE_SEO_CLUSTERS + '/' + SEO_CLUSTERS_BY_PHRASE;
const PAGE_SEO_CLUSTERS_ARTICLE = PAGE_SEO_CLUSTERS + '/' + SEO_CLUSTERS_BY_ARTICLE;
const PAGE_SEO_CLUSTERS_GROUP = PAGE_SEO_CLUSTERS + '/' + SEO_CLUSTERS_BY_GROUP;
const SEO_HINTS = 'seo_hints';
const SEO_SMART = 'seo_smart';
const SEO_SMART_DESCRIPTION_WORDS = 'seo_smart_description_words';
const PAGE_SEO_PRODUCT = '/seo/product';

export {
  BY_HINTS,
  BY_PHRASES,
  BY_PRODUCTS,
  NESTED,
  PAGE_SEO_BY_PHRASE,
  PAGE_SEO_CLUSTERS,
  PAGE_SEO_CLUSTERS_ARTICLE,
  PAGE_SEO_CLUSTERS_GROUP,
  PAGE_SEO_CLUSTERS_REQUEST,
  PAGE_SEO_COMPARE,
  PAGE_SEO_HINTS,
  PAGE_SEO_MONITORING,
  PAGE_SEO_PRODUCT,
  PAGE_SEO_SMART,
  PAGE_SEO_SMART_DESCRIPTION,
  SEO_BY_PHRASE,
  SEO_CLUSTERS_BY_ARTICLE,
  SEO_CLUSTERS_BY_GROUP,
  SEO_CLUSTERS_BY_PHRASE,
  SEO_COMPARE,
  SEO_HINTS,
  SEO_MONITORING_PHRASES,
  SEO_MONITORING_PRODUCTS,
  SEO_SMART,
  SEO_SMART_DESCRIPTION_WORDS,
  TAB_FIRST,
  TAB_SECOND,
};
