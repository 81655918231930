import RefreshIcon from 'assets/icons/RefreshIcon';
import ButtonPrimary from 'components/ButtonPrimary';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import NavList from '../../../components/NavList';
import { PAGE_SEO_CLUSTERS_ARTICLE, PAGE_SEO_CLUSTERS_GROUP } from '../../../constants';
import { ClustersInput } from './ClustersInput';
import { ClustersResults } from './ClustersResults';
import { getClusterInfoByType, MODES } from './utils';

const Clusters = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const history = useHistory();
  const [mode, setMode] = useState(search ? MODES.RESULT : MODES.INSERT);
  const { tab } = useParams();
  const clustersInfo = getClusterInfoByType(tab, searchParams);

  useEffect(() => {
    if (!tab) {
      history.push(PAGE_SEO_CLUSTERS_ARTICLE);
    }
  }, [tab, history]);

  const onReset = () => {
    setMode(MODES.INSERT);
  };
  return (
    <div>
      {mode === MODES.RESULT ? (
        <>
          <div className="pt-4">
            <div className="max-w-[800px] px-8">
              <h1>{searchParams == '' ? 'Кластеры' : clustersInfo.title}</h1>
              <div className="text-gray-400 mt-1 mb-4">{clustersInfo.subTitle}</div>
            </div>
          </div>
          <div className="p-4 px-8 bg-gray-100 border-y border-gray-200">
            <ButtonPrimary
              onClick={onReset}
              className={'items-center space-x-1 text-base'}
              label={
                <>
                  <RefreshIcon width={16} height={16} className={'mb-0.5'} />{' '}
                  <span>Новый запрос</span>
                </>
              }
            />
          </div>
          <ClustersResults setMode={setMode} />
        </>
      ) : (
        <>
          <div className="pt-4">
            <div className="max-w-[800px] px-8">
              <h1>Кластеры</h1>
              <div className="text-gray-400 mt-1 mb-4">{clustersInfo.subTitle}</div>
              <NavList
                items={[
                  // {
                  //   link: PAGE_SEO_CLUSTERS_REQUEST,
                  //   title: 'По запросу',
                  // },
                  {
                    link: PAGE_SEO_CLUSTERS_ARTICLE,
                    title: 'По артикулу',
                  },
                  {
                    link: PAGE_SEO_CLUSTERS_GROUP,
                    title: 'По группе запросов',
                  },
                ]}
                extCallback={() => setMode(MODES.INSERT)}
              />
            </div>
          </div>
          <ClustersInput setMode={setMode} />
        </>
      )}
    </div>
  );
};

export { Clusters };
