import { Redirect } from 'react-router-dom';

import HelpBlock from 'components/HelpBlock';
import { HELP_BLOCK_TYPES } from 'components/HelpBlock/constants';
import ListAlerts from 'components/ListAlerts';
import Table from 'components/Table';
import { PAGE_MAIN } from 'constants';
import { TABLE_TYPE } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useUserTariffs } from 'context/user.tariffs.context';
import { useFetchFilteredDataPaginated } from 'hooks/useFetchFilteredData';

const Subjects = () => {
  const { isSellersAvailable } = useUserTariffs();

  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredDataPaginated({
    type: TABLE_TYPE.SUBJECTS,
  });

  const { isOzonPlatform } = useFilterReducer();

  if (!isSellersAvailable) {
    return <Redirect to={PAGE_MAIN} />;
  }

  return (
    <>
      <HelpBlock type={HELP_BLOCK_TYPES.SUBJECTS} />
      <Table
        data={data}
        type={TABLE_TYPE.SUBJECTS}
        hideSoldColumn={isOzonPlatform}
        isSellerCellClickable={isSellersAvailable}
        paginationOnBack={true}
        isLoading={isLoading || (isFetching && !hasNextPage)}
        noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
      />
      <ListAlerts />
    </>
  );
};

export default Subjects;
